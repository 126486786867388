import { ContactPoint, PostalAddress, GeoCircle, OfferCatalog, Review } from 'schema-dts'

import {
	BASE_URL,
	COMPANY_PHONE,
	SOCIAL_FACEBOOK_URL,
	SOCIAL_GOOGLE_URL,
	SOCIAL_NEXTDOOR_URL
} from '@/constants/index.js'

export const JSON_LD_LOGO = `${BASE_URL}/images/logo.svg`
export const JSON_LD_LATITUDE = '39.517790'
export const JSON_LD_LONGITUDE = '-104.763700'
export const JSON_LD_ADDRESS = '10940 S Parker Rd #418'
export const JSON_LD_CITY = 'Parker'
export const JSON_LD_STATE = 'Colorado'
export const JSON_LD_ZIP = '80138'
export const JSON_LD_HOURS = 'Mo 09:00-17:00 Tu 09:00-17:00 We 09:00-17:00 Th 09:00-17:00 Fr 09:00-17:00'
export const JSON_LD_MAP_URL = 'https://goo.gl/maps/rpgNecFHtDbr9UAt5'

export const JSON_LD_SAME_AS_FACEBOOK_URL = SOCIAL_FACEBOOK_URL
export const JSON_LD_SAME_AS_GOOGLE_URL = SOCIAL_GOOGLE_URL
export const JSON_LD_SAME_AS_NEXTDOOR_URL = SOCIAL_NEXTDOOR_URL

export const JSON_LD_ORGANIZATION_CONTACT_POINT: ContactPoint = {
	'@type': 'ContactPoint',
	telephone: COMPANY_PHONE,
	contactType: 'customer support'
}

// sameAs is typed as a string
export const JSON_LD_ORGANIZATION_SAME_AS = [
	SOCIAL_FACEBOOK_URL,
	SOCIAL_GOOGLE_URL,
	SOCIAL_NEXTDOOR_URL
]

export const JSON_LD_ORGANIZATION_ADDRESS: PostalAddress = {
	'@type': 'PostalAddress',
	streetAddress: JSON_LD_ADDRESS,
	addressLocality: JSON_LD_CITY,
	addressRegion: JSON_LD_STATE,
	postalCode: JSON_LD_ZIP,
	addressCountry: 'United States'
}

export const JSON_LD_ORGANIZATION_AREA_SERVED: GeoCircle = {
	'@type': 'GeoCircle',
	name: 'Service Area',
	geoMidpoint: {
		'@type': 'GeoCoordinates',
		latitude: JSON_LD_LATITUDE,
		longitude: JSON_LD_LONGITUDE
	},
	geoRadius: '100 miles'
}

export const JSON_LD_HAS_OFFER_CATALOG: OfferCatalog = {
	'@type': 'OfferCatalog',
	name: 'Internet Services',
	itemListElement: [
		{
			'@type': 'Offer',
			itemOffered: {
				'@type': 'Service',
				name: '150Mbps High-Speed Internet',
				description: 'Fast and reliable Fiber internet.'
			}
		},
		{
			'@type': 'Offer',
			itemOffered: {
				'@type': 'Service',
				name: '500Mbps High-Speed Internet',
				description: 'Fast and reliable Fiber internet.'
			}
		},
		{
			'@type': 'Offer',
			itemOffered: {
				'@type': 'Service',
				name: '1000Mbps High-Speed Internet',
				description: 'Fast and reliable Fiber internet.'
			}
		}
	]
}

export const JSON_LD_ORGANIZATION_REVIEW: Review = {
	'@type': 'Review',
	reviewRating: {
		'@type': 'Rating',
		ratingValue: '5',
		bestRating: '5',
		ratingExplanation: 'Amazing service, knowledgeable staff, fantastic support.'
	},
	author: {
		'@type': 'Person',
		name: 'PJ G.'
	}
}

export default {
	JSON_LD_LOGO,
	JSON_LD_ADDRESS,
	JSON_LD_CITY,
	JSON_LD_STATE,
	JSON_LD_ZIP,
	JSON_LD_HOURS,
	JSON_LD_MAP_URL,
	JSON_LD_SAME_AS_FACEBOOK_URL,
	JSON_LD_SAME_AS_GOOGLE_URL,
	JSON_LD_SAME_AS_NEXTDOOR_URL,
	JSON_LD_ORGANIZATION_CONTACT_POINT,
	JSON_LD_ORGANIZATION_SAME_AS,
	JSON_LD_ORGANIZATION_ADDRESS,
	JSON_LD_ORGANIZATION_AREA_SERVED,
	JSON_LD_ORGANIZATION_REVIEW,
	JSON_LD_HAS_OFFER_CATALOG
}
